import React, { useContext, useState } from 'react'
import styles from './style/ClaimCarLevelResultDashboad.module.scss'
import downloadBtn from '../img/download.png'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context'
import { makeAuthRequest } from './context/utils'
import Cookies from 'js-cookie'
import lang from './language/ClaimCarLevelResultDashboard.json'
import tick from '../img/tick.png'
import closeImage from "../img/modalClose.png"
import modalLoading from '../img/modalLoading.gif'
import config from '../config/config.json'

const ClaimCarlevelResultDashboard = () => {
    const {urlLink, stGobainComp, octoClients} = config

    const {photowiseDetails, agentApprove, setAgentApprove, language, showApproveModal, setShowApproveModal, showCarRemarkModal, 
            setShowCarRemarkModal, carRemark, setCarRemark, setPhotowiseDetails, setPhotowiseLoading, clientType, isGTEstimateActive,
            openGTResponseModal, setOpenGTResponseModal, GTResponseMessage, setGTResponseMessage, vin, setVin, showVinModal, setShowVinModal,
            sogesaLoading, setSogesaLoading, showSogesaModal, setShowSogesaModal, infoCarCode, setInfoCarCode, configData} = useContext(AppContext)

    const { id } = useParams()
    const navigate = useNavigate()

    const [isResponseDownloading, setisResponseDownloading] = useState(false)
    const [stGobainId, setStGobainId] = useState("")
    const [disapprovedReason, setDisapprovedReason] = useState("")
    const [showDissaproveModal, setShowDissaproveModal] = useState(false)

    const downloadPDF = async() => {
        const res = await makeAuthRequest(`${urlLink}download/report`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), language})
        
        if(res.ok){
            const data = await res.json()
            window.open(data.url, "_blank")
        }
    }

    const downloadImages = async() => {
        const res = await makeAuthRequest(`${urlLink}download/images`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original"})
        
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const downloadJSON = async() => {
        const res = await makeAuthRequest(`${urlLink}download/json`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType')})
        
        if(res.ok){
            const data = await res.json()
            if(data.status === "failed") return alert(data.url)
            window.open(data.url, "_blank")
        }
    }

    const getInspectionDetails = async() => {
        setPhotowiseLoading(true)
        const res = await makeAuthRequest(`${urlLink}photowise-details`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId") })
        const data = await res.json()
        if(res.ok){
          setPhotowiseDetails(data)
          setPhotowiseLoading(false)
        }
        
      }

    const sendApproval = async () => {
        setShowApproveModal(true)
        const res = await makeAuthRequest(`${urlLink}agent/approved`, {client_id: Cookies.get('client'), inspection_id: id, agent_approved: agentApprove, client_type: Cookies.get('clientType'), disapproval_reason: disapprovedReason, case_id: photowiseDetails["unified_json"]["caseId"]})

        if(res.ok){
            setShowDissaproveModal(false)
            setTimeout(()=>setShowApproveModal(false), 1000)
        }
    }

    const sendRemarkData = async () => {
    
        const res = await makeAuthRequest(`${urlLink}remark/update`, {inspection_id: id, remark: carRemark, client_id: Cookies.get('client'), page_no: "", client_type: Cookies.get('clientType')})
        setCarRemark("")
    
        if(res.ok){
          setShowCarRemarkModal(false)
          getInspectionDetails()
        }
    
    }

    const getGTEstimate = async() => {
        if(!isGTEstimateActive) return
        navigate(`/estimate/${id}`)
    }

    const dowloadGTReport = async() => {
        if(!isGTEstimateActive) return
        const res = await makeAuthRequest(`${urlLink}download/pdf`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        if(res.ok){
            if(data.file_url) return window.open(data.file_url, "_blank")
        }
    }

    const downloadDomusReport = async() => {
        if(photowiseDetails["unified_json"]["preInspection"]["damagedParts"].length === 0){
            setGTResponseMessage(lang["No damage found"][language])
            setOpenGTResponseModal(true)
            return
        }
        const res = await makeAuthRequest(`${urlLink}/domus/download/report`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        if(res.ok){
            if(data.status === "success"){
                return window.open(data.url, "_blank")
            }else{
                setGTResponseMessage(data.url)
                setOpenGTResponseModal(true)
            }
        }
    }

    const getGTResponse = async() => {
        if(isResponseDownloading) return
        setisResponseDownloading(true)
        setTimeout(() => {
            setisResponseDownloading(false)
        }, 8000);
        const res = await makeAuthRequest(`${urlLink}get_gtMotive_response`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        if(res.ok){
            if(data.status === "Success"){
                const blob = new Blob([data.response], { type: 'text/plain' })
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', "response.txt")
                document.body.appendChild(link)
                link.click()
                link.remove()
            }
        }
    }

    const sendSogesaData = async() => {
        if(!infoCarCode) return
        setShowSogesaModal(true)
        const res = await makeAuthRequest(`${urlLink}domusPdf`, {infocarcode: infoCarCode, inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        console.log(data)
        if(res.ok){
            if(data.status === "error"){
                setSogesaLoading("error")
                setTimeout(()=>closeSogesaModal(), 2000)
                return
            }
            setSogesaLoading("completed")
            setTimeout(()=>closeSogesaModal(), 2000)
            if(data.url){
                window.open(data.url, "_blank")
            }
        }
    }

    const downloadPersonaImages = async() => {
        if(!stGobainId){
            return alert("Please select an insurer")
        }
        const res = await makeAuthRequest(`${urlLink}download/images/persona`, {client_id: Cookies.get('master'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original", insurer_name: stGobainId})
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const closeSogesaModal = () => {
        setShowSogesaModal(false)
        setInfoCarCode("")
        setSogesaLoading("loading")
    }

    const sendVinData = async () => {
        if(!vin) return
        const res = await makeAuthRequest(`${urlLink}updateVinGT`, {inspection_id: id, vin: vin, client_id: Cookies.get('client'), page_no: "", client_type: Cookies.get('clientType')})
        setVin("")
        setShowVinModal(false)
    
        if(res.ok){
          getInspectionDetails()
        }
    
    }

    const severityColor = (value) => {
        if(value == 0) return "green"
        if(value > 0 && value <= 5) return "#b59f26"
        if(value > 5 && value <= 50) return "#FF7F50"
        if(value > 50 && value <= 75) return "red"
        if(value > 75) return "darkred"
    }

    const getRadius = () => {
        return `${565.2 * ((100 - photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])/100)}px`
    }

    const repairColor = (value) => {
        if(value == "No Repair") return "green"
        if(value == "Smart Repair") return "yellow"
        if(value == "Bodyshop Repair") return "orange"
        if(value == "Glass Repair") return "red"
        if(value == "Partially Total Loss") return "red"
        if(value == "Total Loss") return "darkred"
        return "black"
    }

    return (
    <div className={styles.resultDashboard}>
        <div className={styles.dashBoardContent}>
            <div className={styles.dashboardHeader}>
                <p className={styles.dashboardTitle}>{lang["Inspection Results"][language]}</p>
                <p className={styles.dashboardSub}>{lang["Inspection ID"][language]} {id} {photowiseDetails["batch_hit_time"]}</p>
                <div>
                    <button onClick={()=>downloadPDF()} className={styles.downloadBtn}>{lang["Download PDF"][language]} <img src={downloadBtn} alt="Download" /></button>
                    <button onClick={()=>downloadJSON()} className={styles.downloadBtn}>{lang["Download JSON"][language]} <img src={downloadBtn} alt="Download" /></button>
                    <button onClick={()=>downloadImages()} className={styles.downloadBtn}>{lang["Download Images"][language]} <img src={downloadBtn} alt="Download" /></button>
                </div>
                { clientType === "gt_motive" && <div>
                    <button onClick={()=>getGTEstimate()} style={{backgroundColor : isGTEstimateActive ? "#157AFF" : "#535350"}} className={styles.downloadBtn}> {lang["GT Estimate"][language]} </button>
                    <button onClick={()=>dowloadGTReport()} style={{backgroundColor : isGTEstimateActive ? "#157AFF" : "#535350"}} className={styles.downloadBtn}> {lang["GT Report Download"][language]} <img src={downloadBtn} alt="Download" /></button>
                    <button onClick={()=>getGTResponse()} className={styles.downloadBtn}> {lang["Check GT Response"][language]} </button>
                </div>}
                { clientType === "domus" && <div>
                    <button onClick={()=>downloadDomusReport()} className={styles.downloadBtn}> {lang["Domus Report Download"][language]} <img src={downloadBtn} alt="Download" /></button>
                </div>}
                { clientType === "sogesa" && <div>
                    <input
                    type="text"
                    placeholder='Info Car Code'
                    value={infoCarCode}
                    onChange={(e)=>{
                        setInfoCarCode(e.target.value)
                    }}
                    >
                    </input>
                    <button onClick={()=>sendSogesaData()} style={{backgroundColor : infoCarCode ? "#157AFF" : "#535350"}} className={styles.downloadBtn}> {lang["Submit"][language]} </button>
                </div>}
                {Cookies.get('master') === "client_989" && <div style={{marginTop: "20px"}}>
                    <select value={stGobainId} onChange={(e)=>setStGobainId(e.target.value)}>
                        <option value= "">Select insurer</option>
                        {stGobainComp.map((item, index) => {
                        return <option key={index} value={item}>{item}</option>
                        })}
                    </select>
                    <button style={{margin: "0px"}} onClick={()=>downloadPersonaImages()} className={styles.downloadBtn}> {lang["Dowload Selected Images"][language]} </button>
                </div>}
            </div>
            <div className={styles.buttonSection}>
                {photowiseDetails["unified_json"]["additionalFeatures"]["fastTrackFlag"] && <button className={styles.dashboardButton}>{lang["Fast Track Claim"][language]}</button>}
                {(photowiseDetails["unified_json"]["claim"] !== {} && photowiseDetails["unified_json"]["claim"]['vehicleData']) && <button className={styles.dashboardButton}>{photowiseDetails["unified_json"]["claim"]['vehicleData']['impactType']}</button>}
                {(photowiseDetails["unified_json"]["incompleteUpload"] && photowiseDetails["unified_json"]["incompleteUpload"] === "Yes") && <button className={styles.dashboardButton}>{lang["Incomplete Upload"][language]}</button>}
                {(photowiseDetails["unified_json"]["preInspection"] !== {} && photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] !== "N") && <button className={styles.dashboardButton}>{lang["Recommended"][language]}</button>}
            </div>

        </div>
            <hr/>
            <div className={styles.dashboardTable} >
                <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Type of inspection"][language]}</p>
                        {photowiseDetails["web_app_case"] === "0"? <p>{lang["API/Portal Upload"][language]}</p>: <p>Super App</p>}
                    </div>
                </div>
                {Object.keys(photowiseDetails["unified_json"]).includes("uploadStatus") ? <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Upload Status"][language]}</p>
                        {photowiseDetails["unified_json"]["uploadStatus"] === "complete upload" ? <p style={{color: "green"}}>{lang["Complete Upload"][language]}</p>: <p style={{color: "red"}}>{lang["Incomplete Upload"][language]}</p>}
                    </div>
                </div> : null}
                <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["License Plate"][language]}</p>
                        {photowiseDetails["unified_json"]["vehicleReadings"]["licensePlateReading"] ? <div style={{background: "none"}}> {photowiseDetails["license_matched"] ? null : lang["License Mismatch"][language]} <p>{photowiseDetails["unified_json"]["vehicleReadings"]["licensePlateReading"]}</p></div>: <p>----</p>}
                    </div>
                </div>
                {!(octoClients.includes(Cookies.get("master"))) && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Damage Severity Score"][language]}</p>
                        {/* {photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"] ? <div style={{borderWidth: "4px", borderStyle: "solid", borderColor: `${severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])}`, borderRadius: "50%", width: "45px", height: "45px"}}><p style={{padding: "5px"}}>{photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"]}</p></div>: <p>----</p>} */}
                        {Object.keys(photowiseDetails["unified_json"]).includes("totalLoss") ? 
                        <svg width="60" height="60" viewBox="-25 -25 250 250" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{transform: "rotate(-90deg)"}}>
                            <circle r="90" cx="100" cy="100" fill="transparent" stroke="#e0e0e0" stroke-width="20px" stroke-dasharray="565.48px" stroke-dashoffset="0"></circle>
                            <circle r="90" cx="100" cy="100" stroke={severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])} stroke-width="20px" stroke-linecap="round" stroke-dashoffset={getRadius()} fill="transparent" stroke-dasharray="565.2px"></circle>
                            <text x={photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"] === 100 ? "59px" : photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"] >= 10 ? "73px": "86px"} y="117px" fill={severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])} font-size="52px" font-weight="bold" style={{transform:"rotate(90deg) translate(0px, -196px"}}>{Math.floor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])}</text>
                        </svg>: <p>----</p>}
                    </div>
                </div>}
                {!(octoClients.includes(Cookies.get("master"))) && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Repair Workflow"][language]}</p>
                        {(Object.keys(photowiseDetails["unified_json"]).includes("totalLoss") && Object.keys(photowiseDetails["unified_json"]["totalLoss"]).length >=3)  ? <p style={{color: `${severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])}`}}>{photowiseDetails["unified_json"]["totalLoss"]["repairDecision"]}</p>: <p>----</p>}
                    </div>
                </div>}
                { Cookies.get("client") === "agent_hiib_liberty_01" && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>Status Remark</p>
                        <p>{photowiseDetails["unified_json"]["preInspection"]["message"] ? photowiseDetails["unified_json"]["preInspection"]["message"] : "---"}</p>
                    </div>
                </div>}
                {!(octoClients.includes(Cookies.get("master"))) && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Make"][language]}</p>
                        {Object.keys(photowiseDetails["unified_json"]["claim"]).length !== 0 ? <p>{photowiseDetails["unified_json"]["claim"]['clientRequestData']['make']}</p>: <p>----</p>}
                    </div>
                </div>}
                {!(octoClients.includes(Cookies.get("master"))) && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Model"][language]}</p>
                        {Object.keys(photowiseDetails["unified_json"]["claim"]).length !==0  ? <p>{photowiseDetails["unified_json"]["claim"]['clientRequestData']['model']}</p>: <p>----</p>}
                    </div>
                </div>}
                <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Odometer"][language]}</p>
                        {photowiseDetails["unified_json"]["vehicleReadings"]["odometerReading"] ? <p>{photowiseDetails["unified_json"]["vehicleReadings"]["odometerReading"]}</p>: <p>----</p>}
                    </div>
                </div>
                <div>
                    <div className={styles.dashboardTableBar}>
                    <p>{lang["VIN"][language]}</p>
                        <div>
                            {(photowiseDetails["unified_json"]["vehicleReadings"]["vinReading"] && photowiseDetails["unified_json"]["vehicleReadings"]["vinReading"] !=="_________________") ?  <div> {!photowiseDetails["vin_matched"] && `(${lang["VIN Mismatched"][language]})`} <p>{photowiseDetails["unified_json"]["vehicleReadings"]["vinReading"]}</p></div>: <p>----</p>}
                            {configData["config"]["vinEdit"].includes(Cookies.get('master')) ?
                                <button onClick={()=> {
                                    if(photowiseDetails["unified_json"]["vehicleReadings"]["vinReading"] && photowiseDetails["unified_json"]["vehicleReadings"]["vinReading"] !=="_________________"){
                                        setVin(photowiseDetails["unified_json"]["vehicleReadings"]["vinReading"])
                                    }else{
                                        setVin("----")
                                    }
                                    setShowVinModal(true)
                                }} className={styles.submitBtn}>{lang["Edit"][language]}</button> : null}
                        </div>
                    </div>
                </div>
                {!(octoClients.includes(Cookies.get("master"))) && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Fuel Meter"][language]}</p>
                        {photowiseDetails["unified_json"]["vehicleReadings"]["fuelMeterReading"] ? <p>{photowiseDetails["unified_json"]["vehicleReadings"]["fuelMeterReading"]}</p>: <p>----</p>}
                    </div>
                </div>}
                {!(octoClients.includes(Cookies.get("master"))) && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Cause of Damage"][language]}</p>
                        {Object.keys(photowiseDetails["unified_json"]["claim"]).length !==0  ? <p>{photowiseDetails["unified_json"]["claim"]['vehicleData']['causeOfDamage']}</p>: <p>----</p>}
                    </div>
                </div>}
                {!(octoClients.includes(Cookies.get("master"))) && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Glass Damage Region"][language]}</p>
                        {Object.keys(photowiseDetails["unified_json"]["additionalFeatures"]).includes("windshieldDamageRegion") ? photowiseDetails["unified_json"]["additionalFeatures"]["windshieldDamageRegion"] ? <p>{photowiseDetails["unified_json"]["additionalFeatures"]["windshieldDamageRegion"]}</p>: <p>----</p>: <p>----</p>}
                    </div>
                </div>}
                {(Cookies.get("client") !== "admin_raheja_agent" && Cookies.get("master") !== "client_507" && Cookies.get("master") !== "client_1121" && Cookies.get("master") !== "client_1217") && <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{lang["Approve Inspection"][language]}</p>
                        <div>
                            {Cookies.get('master') === "easyautotech" || window.top.location.host === "results.easydetect.ai" || Cookies.get('master') === "client_1124" || window.top.location.host === "inspection-vn.iglooinsure.com" ? 
                            <select value={agentApprove} onChange={(e)=>setAgentApprove(e.target.value)}>
                            {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] !== "N" ? <option value="Approved">{lang["Approve"][language]} ({lang["Recommended"][language]})</option>: <option value="Approved">{lang["Approve"][language]}</option>}
                            {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] === "N" ? <option value="Disapproved">{lang["Disapprove"][language]} ({lang["Recommended"][language]})</option>: <option value="Disapproved">{lang["Disapprove"][language]}</option>}
                            </select> : 
                            <select value={agentApprove} onChange={(e)=>setAgentApprove(e.target.value)}>
                                {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] !== "N" ? <option value="Approved">{lang["Approve"][language]} ({lang["Recommended by Inspektlabs"][language]})</option>: <option value="Approved">{lang["Approve"][language]}</option>}
                                {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] === "N" ? <option value="Disapproved">{lang["Disapprove"][language]} ({lang["Recommended by Inspektlabs"][language]})</option>: <option value="Disapproved">{lang["Disapprove"][language]}</option>}
                                {Cookies.get("master") === "claim_ydrogios" && <option value="Stand by">Αναμονή</option>}
                                {Cookies.get("master") === "claim_ydrogios" && <option value="Complete">Ολοκληρωμένηqa</option>}
                            </select>}
                            <button onClick={()=>{
                                if(Cookies.get("master") === "client_1257" || Cookies.get("master") === "client_1257_uat"){
                                    if(agentApprove === "Disapproved"){
                                        setShowDissaproveModal(true)
                                    }
                                    else{
                                        sendApproval()
                                    }
                                }
                                else{
                                    sendApproval()
                                }
                            }} className={styles.submitBtn}>{lang["Submit"][language]}</button>
                        </div>
                    </div>
                </div>}
                <div>
                    <div className={styles.dashboardTableBar}>
                        <p>{Cookies.get("master") === "gipsa_inspekt" ? "TAT(min)" : lang["Remark"][language]}</p>
                        <div className={styles.remarkContainer} style={{background: "none"}}>
                            <p className={styles.remarkPara}>{photowiseDetails.remarks}</p>
                            <button onClick={()=>{
                                setShowCarRemarkModal(true)
                                if(photowiseDetails.remarks) setCarRemark(photowiseDetails.remarks)
                            }} className={styles.submitBtn}>{lang["Add"][language]}</button>
                        </div>
                    </div>
                </div>
            </div>
        {showCarRemarkModal && <div className={styles.remarkModalContainer}>
            <div className={styles.remarkModal}>
                <h3>{lang["Add Remark"][language]}</h3>
                <textarea
                    value = {carRemark}
                    onChange={(e)=> setCarRemark(e.target.value)}
                    className={styles.remarkInput}
                >
                </textarea>
                <div className={styles.remarkModalButtonContainer}>
                    <div onClick={()=>sendRemarkData()}>{lang["Save"][language]}</div>
                    <div onClick={()=>{
                    setShowCarRemarkModal(false)
                    setCarRemark("")
                    }}>{lang["Close"][language]}</div>
                </div>
            </div>
        </div>}
        {showApproveModal && 
            <div className={styles.polcyModalContainer}>
                <div style={{display: "flex"}} className={styles.policyModalMain}>
                    <img className={styles.tick} src={tick} alt="tick"></img>
                    <h3>{lang["submitted"][language]}</h3>
                </div>
            </div>
        }

        {showDissaproveModal && 
            <div className={styles.remarkModalContainer}>
                <div className={styles.remarkModal} style={{height: "140px", width: "500px"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5em"}}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5em"}}>
                            <input
                            type='radio'
                            value='U/w reasons'
                            checked={disapprovedReason === "U/w reasons"}
                            onChange={()=>setDisapprovedReason("U/w reasons")}
                            />
                            <label htmlFor="U/w reasons">
                                U/w reasons
                            </label>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5em"}}>
                            <input
                            type='radio'
                            value='Bad image quality'
                            checked={disapprovedReason === "Bad image quality"}
                            onChange={()=>setDisapprovedReason("Bad image quality")}
                            />
                            <label htmlFor="Bad image quality">
                                Bad image quality
                            </label>
                        </div>
                    </div>
                    <div className={styles.remarkModalButtonContainer}>
                        <div onClick={()=>sendApproval()}>{lang["Submit"][language]}</div>
                        <div onClick={()=>{
                            setDisapprovedReason("")
                            setShowDissaproveModal(false)}}>{lang["Close"][language]}</div>
                    </div>
                </div>
            </div>
        }

        {showVinModal && <div className={styles.vinModalContainer}>
            <div className={styles.vinModal}>
                <h3>{lang["VIN"][language]}</h3>
                <input
                    type='text'
                    value = {vin}
                    onChange={(e)=> setVin(e.target.value)}
                    className={styles.vinInput}
                >
                </input>
                <div className={styles.vinModalButtonContainer}>
                    <div onClick={()=>sendVinData()}>{lang["Save"][language]}</div>
                    <div onClick={()=>{
                    setShowVinModal(false)
                    setVin("")
                    }}>{lang["Close"][language]}</div>
                </div>
            </div>
        </div>}

        {openGTResponseModal && 
            <div className={styles.polcyModalContainer}>
                <div style={{display: "flex", justifyContent: "center", height: "100px"}} className={styles.policyModalMain}>
                    <img className={styles.CloseButton} src={closeImage} alt='X' onClick={()=>{
                        setGTResponseMessage("")
                        setOpenGTResponseModal(false)
                    }}/>
                    <h3>{GTResponseMessage}</h3>
                </div>
            </div>
        }

        {showSogesaModal && 
            <div className={styles.polcyModalContainer}>
                { sogesaLoading === "loading" ? 
                <div style={{display: "flex"}} className={styles.policyModalMain}>
                    <img className={styles.loading} src={modalLoading} alt="loading..."></img>
                </div> :
                sogesaLoading === "error" ? 
                <div style={{display: "flex"}} className={styles.policyModalMain}>
                    <h4 style={{color: "red", textAlign: "center", marginTop: "30px"}}>{lang["sogesaError"][language]}</h4>
                </div>: 
                <div style={{display: "flex"}} className={styles.policyModalMain}>
                    <img className={styles.tick} src={tick} alt="tick"></img>
                    <h3>{lang["submitted"][language]}</h3>
                </div>}
            </div>}
    </div>  )
}

export default ClaimCarlevelResultDashboard