import React from 'react'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CarlevelResult from './CarlevelResult'
import CarlevelResultDashboard from './CarlevelResultDashboard'
import { AppContext } from './context'
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils'
import Navbar from './Navbar'
import loadingImg from '../img/loading.gif'
import PartlevelDashboad from './PartlevelDashboad'
import PartLevelResult from './PartLevelResult'
import styles from './style/InspectionResult.module.scss'
import Cookies from 'js-cookie'
import config from '../config/config.json'


const InspectionResult = () => {

  const { swipe, setPhotowiseDetails, photowiseLoading, setPhotowiseLoading, setLanguage, setLanguageList, setModule, setConfigData, setEditMode, setLicensePlate, 
          setMake, setModel, setOdometer, setVin, setFuelMeter, setCarLevelEditData, setPartLevelEditData, setPartCheckBox, setEditRow, setPartLevelDisplayData,
          setIsGTEstimateActive, isMahindraClient, setOpen, setAllowDamageModal, setIsHailDamage, setIsSizeOfDamage, setIsInternalDamage} = useContext(AppContext)
  const {urlLink} = config
  const { id } = useParams()
  const navigate = useNavigate()

  const getInspectionDetails = async() => {
    setAllowDamageModal(false)
    setIsInternalDamage(false)
    setIsSizeOfDamage(false)
    setIsHailDamage(false)
    const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}photowise-details`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), agent_tl_view: "", agent_tl_id: ""}) : await makeAuthRequest(`${urlLink}photowise-details`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId") })
    const data = await res.json()
    if(res.ok){
      let temp = {
        "licensePlate": data["unified_json"]["vehicleReadings"]["licensePlateReading"],
        "make": Object.keys(data["unified_json"]["claim"]).length !== 0 ? data["unified_json"]["claim"]['clientRequestData']['make'] : "",
        "model": Object.keys(data["unified_json"]["claim"]).length !== 0 ? data["unified_json"]["claim"]['clientRequestData']['model'] : "",
        "odometer": data["unified_json"]["vehicleReadings"]["odometerReading"],
        "vin": data["unified_json"]["vehicleReadings"]["vinReading"],
        "fuelmeter": data["unified_json"]["vehicleReadings"]["fuelMeterReading"]
      }

      let checkBoxArray = new Array(data["unified_json"]["preInspection"]["damagedParts"].length).fill("false")
      setPartCheckBox(checkBoxArray)

      setPhotowiseDetails(data)
      if(data["unified_json"]["additionalFeatures"]["highProbabilityInternalDamages"].length > 0){
        setAllowDamageModal(true)
        setIsInternalDamage(true)
      }
      if(Object.keys(data["unified_json"]["additionalFeatures"]["sizeOfDamage"]).length > 0){
        setAllowDamageModal(true)
        setIsSizeOfDamage(true)
      }
      if(Object.keys(data["unified_json"]["additionalFeatures"]).includes("hailDamage") && Object.keys(data["unified_json"]["additionalFeatures"]["hailDamage"]).length > 0){
        setAllowDamageModal(true)
        setIsHailDamage(true)
      }
      
      getConfig()
      setLicensePlate(temp['licensePlate'])
      setMake(temp['make'])
      setModel(temp['model'])
      setOdometer(temp['odometer'])
      setVin(temp['vin'])
      setFuelMeter(temp['fuelmeter'])
      setCarLevelEditData(temp)
      setPartLevelEditData(data["unified_json"]["preInspection"]["damagedParts"])
      setPartLevelDisplayData(data["unified_json"]["preInspection"]["damagedParts"])
    }
    
  }

  const getConfig = async() => {
    const res = await makeAuthRequest(`${urlLink}getConfig`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType') })
    const data = await res.json()
    if(res.ok){
      setConfigData(data)
      setPhotowiseLoading(false)
    }
    
  }

  const getGTEStimateStatus = async() => {
    const res = await makeAuthRequest(`${urlLink}check_gtEstimate_button`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType') })
    const data = await res.json()
    if(res.ok){
      if(data.status === "Success"){
        setIsGTEstimateActive(data.response)
      }
    }
  }

  useEffect(()=>{
    setIsGTEstimateActive(false)
    setPhotowiseLoading(true)
    setEditMode(false)
    setEditRow(false)
    setOpen(false)
    setIsHailDamage(false)
    setIsInternalDamage(false)
    setIsSizeOfDamage(false)
    if(!checkCookies()){
      removeCookies()
      navigate('/login')
    }
    else{
      if(!id){
        if(Cookies.get("isMahindra")) return navigate('/vehicle-listing')
        return navigate('/pre-inspection')
      }
      else {
        setLanguage(Cookies.get('current-language'))
        setModule(JSON.parse(Cookies.get('modules')))
        setLanguageList(JSON.parse(Cookies.get('all-languages')))
        getInspectionDetails()
        getGTEStimateStatus()
      } 

    } 
  }, [navigate, setPhotowiseLoading, id, setLanguage, setLanguageList, setModule])
  
  return (
    <div className={styles.container}>
        <Navbar/>
        { photowiseLoading ? 
        <div className={styles.loading} >
          <img src={loadingImg} alt='loading...'/>
        </div>:
        <div className={styles.inspectionResultSwitch}>
        { swipe === "carLevelResult" && <CarlevelResult/>}
        { swipe === "carLevelResult" && <CarlevelResultDashboard/>}
        { swipe === "partlevelResult" && <PartLevelResult/>}
        { swipe === "partlevelResult" && <PartlevelDashboad />}
        </div>}
    </div>
  )
}

export default InspectionResult